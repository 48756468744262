import { createSignal, JSX } from "solid-js";
import { eventHandler } from "solid-u";

import UObject from "@repo/utils/UObject";

import ContainerButton from "../buttons/ContainerButton";

declare namespace ClickToCopy {
  type Props = UObject.Override<
    ContainerButton.Props,
    {
      text: string;
      children: (context: {
        icon(): JSX.Element;
        copied(): boolean;
      }) => JSX.Element;
    }
  >;
}

function ClickToCopy({
  text,
  children,
  onClick,
  ...props
}: D<ClickToCopy.Props>) {
  const [copied, setCopied] = createSignal(false);

  return (
    <ContainerButton
      onClick={(e) => {
        navigator.clipboard.writeText(text).then(() => {
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 1500);
        });
        eventHandler(onClick)(e);
      }}
      {...props}
    >
      {children({
        copied,
        icon: () =>
          copied() ? <div class="color:success"></div> : <div></div>,
      })}
    </ContainerButton>
  );
}

export default ClickToCopy;
