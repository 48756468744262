import { createMemo, Show } from "solid-js";

import Detail from "@repo/ui/info/Detail";
import UDate from "@repo/utils/UDate";

import { UserDump } from "~/state/features/users";

function extractDataWithCompletedDt(data: any): {
  completedDt: string;
  errors: { code: string; message: string }[];
  fields: { fieldname: string; status: string }[];
}[] {
  const entries = data.map((item: any) => {
    // item.completedDt can't be converted to a Date object completely since it includes decimals after the seconds.
    // example: 2024-10-22T22:07:34.588315
    const completedDt = item.CompletedDt?.substring(0, 19);

    const fields: { fieldname: string; status: string }[] = [];
    const errors: { code: string; message: string }[] = [];

    if (item.Record?.DatasourceResults) {
      item.Record.DatasourceResults.forEach((result: any) => {
        result.DatasourceFields?.forEach((field: any) => {
          if (field.FieldName && field.Status) {
            fields.push({ fieldname: field.FieldName, status: field.Status });
          }
        });
        result.Errors?.forEach((error: any) => {
          if (error.Code && error.Message) {
            errors.push({ code: error.Code, message: error.Message });
          }
        });
      });
    }
    item.Errors?.forEach((error: any) => {
      if (error.Code && error.Message) {
        errors.push({ code: error.Code, message: error.Message });
      }
    });

    return { completedDt, errors, fields };
  });

  return entries.sort((a: any, b: any) =>
    a.completedDt > b.completedDt ? -1 : 1,
  );
}

declare namespace ParsedIdv {
  type Props = { payload?: Record<any, any>[] };
}

function ParsedIdv({ payload }: D<ParsedIdv.Props>) {
  const data = createMemo(() => {
    try {
      return { fieldsByAttempt: extractDataWithCompletedDt(payload) };
    } catch {
      return { error: "error" };
    }
  });

  const statusColor = (status: string) => {
    switch (status) {
      case "match":
        return "success";
      case "nomatch":
        return "error";
      case "missing":
      default:
        return "moderate-weak";
    }
  };

  return (
    <div class="flex py-2 pl-1">
      <Show
        when={data().fieldsByAttempt}
        fallback={<div class="py-2 pl-1">null</div>}
      >
        {(fieldsByAttempt) =>
          fieldsByAttempt().map((attempt, idx) => (
            <div>
              <div class="text:large+">
                IDV Validation Attempt {idx + 1} on{" "}
                {UDate.format.time(new Date(attempt.completedDt), "hh:mm:ss")}{" "}
                {UDate.format(new Date(attempt.completedDt), "dd Mmmm yyyy")}
              </div>
              {attempt.errors.map((error) => (
                <div class="color:error pb-1">{`ERROR ${error.code}: ${UserDump.TrulioIdvErrorCodes[error.code] ?? error.message}`}</div>
              ))}
              <Show
                when={attempt.fields.length}
                fallback={"Credit agency failed to return fields."}
              >
                {attempt.fields.map((field) => (
                  <Detail
                    variant="row"
                    label={field.fieldname}
                    propsFor={{
                      children: { class: `color:${statusColor(field.status)}` },
                    }}
                  >
                    {field.status.toUpperCase()}
                  </Detail>
                ))}
              </Show>
              <hr />
            </div>
          ))
        }
      </Show>
    </div>
  );
}

export default ParsedIdv;
